import { truncateDisplayName } from '@lardy/core';
import { IStyle } from 'fela';
import { observer } from 'mobx-react-lite';
import { ClassicComponentClass, ComponentClass, createElement, SFC } from 'react';
import { FelaContext } from '../context';

export const withFelaStylings = <P>(getStyles: (props: Partial<P>) => { [index: string]: IStyle }) => {
    return (Component: SFC<P> | ComponentClass<P> | ClassicComponentClass<P>) => {
        const WithFelaStylings = observer((props: P) => {
            return createElement(FelaContext.Provider, {
                value: getStyles(props)
            }, createElement(Component, props));
        });

        (WithFelaStylings as any).displayName = truncateDisplayName(
            Component.displayName,
            'WithFelaStylings'
        );

        return WithFelaStylings;
    };
};
