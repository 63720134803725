import { IElementTransformProps, truncateDisplayName } from '@lardy/core';
import { ComponentType, createElement, forwardRef, PropsWithChildren, useContext } from 'react';
import { useFela } from 'react-fela';
import { FelaContext } from '../context';

export interface IFelaEnrichedProps {
    className?: string;
}

const withFelaComponent = ({
    name
}: IElementTransformProps) => <P>(Component: ComponentType<P>) => {
    const AsFelaComponent = forwardRef<HTMLElement, PropsWithChildren<P & IFelaEnrichedProps>>((props, ref)  => {
        const { className, children, ...otherProps } = props as any;

        const styles = useContext(FelaContext);
        const { css } = useFela();

        console.log(css);

        if (styles === undefined) {
            throw new Error('Could not find fela style context for element');
        }

        return createElement(
            Component as any,
            {
                ref,
                className: [css(styles[name]), className].join(' '),
                ...otherProps
            },
            children
        );
    });

    AsFelaComponent.displayName = truncateDisplayName(
        Component.displayName,
        'AsFelaComponent'
    );

    return AsFelaComponent;
};

export { withFelaComponent };
