import { _htmlElementNames, Extension, ICompositeComponent, IElementComposerFn,
    IMiddlewareExtender,
    TComponent} from '@lardy/core';
import { FelaInjectedProps } from 'react-fela';
// import * as elementList from 'html-element-list/element-list.json';
import { withFelaComponent, withFelaStylings } from './decorators';
import { IStyleFn } from './interfaces';

export class FelaFragment extends Extension {

    public static exports = ['style'];

    public constructor(self: ICompositeComponent) {
        super(self);

        self.define('elementTransforms', withFelaComponent);
        self.define('elementIndex', this.getElementIndex);
    }

    public style<XS>(fn: IStyleFn<this, XS>) {
        const self = this.getSelf();

        self.styleNames = [...self.styleNames, ...Object.keys((fn as any)({}))];

        self.decorate(withFelaStylings(fn as any));

        return (self as unknown) as this extends TComponent<
            infer P,
            infer S,
            infer OP,
            infer EX,
            infer SX,
            infer XP
        >
            ? TComponent<P, S | keyof XS, OP, EX, SX & { __global: FelaInjectedProps<{}, any> }, XP>
            : this;
    }

    private getElementIndex = ($: IElementComposerFn<any, any>) => {
        const names = this.getSelf().styleNames;

        _htmlElementNames.forEach((key) => {
            Object.defineProperty($, key, {
                get() {
                    return names.reduce((elements, name) => ({
                        ...elements,
                        [name]: $(key, name)
                    }), {});
                }
            });
        });
    }
}

const constructor = <P, S, OP, EX, SX, XP>(self: ICompositeComponent<P, S, OP, EX, SX, XP>) => {
    return new FelaFragment(self);
};

(constructor as any).__middlewareRole = 'extender';

const fela = constructor as IMiddlewareExtender<typeof constructor>;

export { fela };
